export const environment = {
  production: false,
  hmr: false,
  apiUrl:"https://vno.ww.co.th/api/v1",
  baseUrl:"https://vno.ww.co.th/api/v1",
  imagePath:"https://vno.ww.co.th/uploads",
  ssoBaseUrl:"https://vno.ww.co.th/api/v1",

  // apiUrl:"http://localhost:4000/api",
  // baseUrl:"http://localhost:4000/api",
  // imagePath:"http://localhost:4000/upload",
  // ssoBaseUrl:"http://localhost:4000/api",
  
  sysName:"True",
  sysVersion:"1.0",
  client_id:"korn-client",
  client_secret:"20bd501c-7276-4c1f-a687-b08d8779174e",
  regis_secret:"dm1zbG9jYWw6UEBzc3cwcmQ=",
  siteKey:"6LdcYfUkAAAAADHk6FCHLNs2Xt4kSONZs4puwzTf",
  realm_id:"korntracking",
  grant_type:"password"
};
