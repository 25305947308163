import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@core/services/http.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient, private http: HttpService, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */
  login(username: string, password: string): Promise<any> {
    let value = "username=" + username + "&password=" + password;
    let token
    let user
    return new Promise((resolv, reject) => {
      this.http.getHttpUrlEncode('auth/signin', value).then(res => {
       // console.log('user', res.result.accessToken)

        // login successful if there's a jwt token in the response
        if (res.result.accessToken) {
          token = res.result.accessToken
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.http.setLocalStorageOne('token', token).then(res => {
          //  console.log('token', token)
            this.http.getHttpJsonGet('auth/profile', token).then(res => {
              let result = res.result
              user = {
                id: result.id,
                username: result.username,
                email: result.email,
                firstName: result.firstname,
                password: "",
                lastName: result.lastname,
                avatar: environment.imagePath + '/' + result.image,
                address: result.address,
                role: result.role,
                token: token
              }

              // notify
              this.currentUserSubject.next(user);
              localStorage.setItem('currentUser', JSON.stringify(user));
              //console.log('profile', user, res)
              return resolv(user);
            }, reject)
          });
        }
      })
    })
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
